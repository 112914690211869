<template>
  <div class="container">
    <b-col
      lg="6"
      sm="12"
      xs="12"
      md="8"
      offset-lg="3"
      offset-md="2"
    >
      <b-card
        class="hubu-bg-red"
        :header="$t('auth.login')"
        header-tag="header"
      >
        <b-form @submit="submitForm">
          <div v-if="errors.invalid">
            {{ errors.invalid }}
          </div>
          <b-form-group
            id="input-group-1"
            :label="$t('auth.email_label')"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              required
              :placeholder="$t('auth.email_placeholder')"
              :state="valid"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            :label="$t('auth.password_label')"
            label-for="input-2"
          >
            <b-input-group>
              <b-form-input
                id="input-2"
                v-model="form.password"
                :type="form.password_field.type"
                required
                minlength="6"
                :placeholder="$t('auth.password_placeholder')"
                :state="valid"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="showPassword"
                >
                  <b-icon :icon="form.password_field.icon" />
                </b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="input-live-feedback">
                {{ $t('ivalid_email_password') }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <router-link
            class="d-inline-block my-2"
            :to="{ name: 'Forgot' }"
          >
            {{ $t('forgot') }}
          </router-link>
          <div>
            <b-button
              class="hubu-btn-red mr-2"
              size="md"
              type="submit"
              variant="primary"
            >
              {{ $t('submit') }}
            </b-button>
            <a :href="appText('Buy_url')">
              <b-button
                class="btn-white"
                size="md"
                variant="primary"
              >{{ $t('auth.register') }}</b-button>
            </a>
          </div>
        </b-form>
      </b-card>
    </b-col>
  </div>
</template>

<script>

export default {
  name: "Login",
  data () {
    return {
      form: {
        email: null,
        password: null,
        password_field: {
          type: "password",
          icon: "eye-slash",
        },
      },
      errors: {
        invalid: null,
      },
      valid: null,
    };
  },
  computed: {
  },
  created () {
    if (this.$store.getters.loggedIn) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    async submitForm (e) {
      e.preventDefault();

      await this.$store
        .dispatch("userLogin", {
          email: this.form.email,
          password: this.form.password,
        }).then(() => {
          this.$router.push({
            name: "Home",
          });
        }).catch(() => {
          this.valid = false;
          return;
        });


    },
    showPassword () {
      if (this.form.password_field.type === "password") {
        this.form.password_field.type = "text";
        this.form.password_field.icon = "eye";
      } else {
        this.form.password_field.type = "password";
        this.form.password_field.icon = "eye-slash";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}
</style>
